<template>
    <v-col   v-if="!loading" cols="12" 
        :md="size === 2 ? 6 : size === 2 ? 6 : size === 3 ? 4 : size === 4 ? 3 : undefined">
        <base-card data-aos="fade-up" color="rgba(203, 170, 92, 0.10)" :href="'video/' + value.id" target="_blank">
            <v-hover v-slot="{ hover }">
                <div style="overflow: hidden;">
                    <v-img :ref="'img' + value.video_id"
                        :src="'https://img.youtube.com/vi/' + value.video_id + '/maxresdefault.jpg'"
                        :lazy-src="`https://picsum.photos/10/6?image=15`"
                        :gradient="hover ? 'rgba(0, 0, 0, .15),rgba(0, 0, 0, .15)' : 'rgba(0, 0, 0, .30),rgba(0, 0, 0, .30)'"
                        :class="{
                            'img-default-size': true,
                            'img-rotate-size': hover,
                        }" aspect-ratio="1.7">

                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-scroll-y-reverse-transition>
                                <v-btn v-if="!hover" small depressed fab :color="'rgba(203, 170, 92, 0.51)'"
                                    target="_blank">
                                    <v-icon v-if="!hover" :color="'rgba(255, 255, 255, 0.90)'" size="30">
                                        mdi-play
                                    </v-icon>
                                </v-btn>
                            </v-scroll-y-reverse-transition>
                        </v-row>
                    </v-img>
                </div>
            </v-hover>
            <v-card-text color="grey">
                <h3 class="title font-weight-bold mb-2 text-truncate">
                    {{ value.title }}
                </h3>
                <div class="caption">
                    <!-- {{ value.author }}
                <br> -->
                    {{ value.date | moment("dddd, MMMM Do YYYY") }}
                </div>
                <div class="caption">
                    <!-- {{ value.author }}
                <br> -->
                    {{ value.date | moment("dddd, MMMM Do YYYY") }}
                </div>
            </v-card-text>
        </base-card>
    </v-col>

</template>
  
<script>
// Utilities
import { BASE_IMAGE_URL } from "@/constants/index";
import {
    mapGetters,
} from 'vuex'

export default {
    name: 'VideoCard',

    data: () => ({
        baseImageUrl: BASE_IMAGE_URL,
    }),
    props: {
        size: {
            type: Number,
            required: true,
        },
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    created() {  this.getVideoStatistics();},
    computed: {
        ...mapGetters(['loading','videoStatistics']),
    },
    methods: {
        getVideoStatistics(){
      this.$store.dispatch('getVideoStatistics', { 'id': this.value.id})
    },

  },


}
</script>
<style>
.img-default-size {
    transition: .3s ease-in-out;
}

.img-rotate-size {

    transition: .3s ease-in-out;
    transform: rotate(5deg) scale(1.2);

}

.hide-btns {
    color: rgba(255, 255, 255, 0) !important;
}
</style>
  